
export default {
  /**
    series: [
      {
        name: String,
        color: String,
        data: [
          { x: String, y: Number },
          { x: String, y: Number },
          { x: String, y: Number },
          etc.
        ],
      },
    ]
  */
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    width() {
      return `${100 / this.series[0].data.length}%`;
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_s1c5m{display:flex;flex-direction:column;height:-moz-fit-content;height:fit-content;width:100%}.background_MR6TP{align-items:center;display:inline-flex;flex-grow:1;height:3rem;justify-content:center;width:100%}.test-heatmap .simple-table-row:not(:last-child){border-bottom:1px solid var(--color-white)}.test-heatmap .simple-table-cell:not(:last-child){border-right:1px solid var(--color-white)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_s1c5m",
	"background": "background_MR6TP"
};
module.exports = ___CSS_LOADER_EXPORT___;
